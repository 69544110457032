import { render, staticRenderFns } from "./TrendingFilterList.vue?vue&type=template&id=abee6ab2&scoped=true&lang=pug&"
import script from "./TrendingFilterList.vue?vue&type=script&lang=ts&"
export * from "./TrendingFilterList.vue?vue&type=script&lang=ts&"
import style0 from "./TrendingFilterList.vue?vue&type=style&index=0&id=abee6ab2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abee6ab2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/clickandrap_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('abee6ab2')) {
      api.createRecord('abee6ab2', component.options)
    } else {
      api.reload('abee6ab2', component.options)
    }
    module.hot.accept("./TrendingFilterList.vue?vue&type=template&id=abee6ab2&scoped=true&lang=pug&", function () {
      api.rerender('abee6ab2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/marketplace/components/TrendingFilterList.vue"
export default component.exports