import { get } from '~/services/http-requests'
import { randomizeList } from '~/utils/randomizeList'

const _BS_GENRES = 'genres'

export async function fetchSingleGenre(genreSlug: IGenre['slug']): Promise<IGenre> {
    const [first] = await get({ baseProperty: _BS_GENRES, fullUrl: `${_BS_GENRES}.json?slug=${genreSlug}` })
    return first
}

export const fetchAllGenres = async (): Promise<IGenre[]> => randomizeList(await get({ baseProperty: _BS_GENRES }))
