var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filtersArray.length > 0
    ? _c(
        "div",
        {
          staticClass: "trendingFilter",
          class: { inTrendingPage: _vm.inTrendingPage },
        },
        [
          _c(
            "div",
            { staticClass: "trendingFilter__mainBlock" },
            _vm._l(_vm.filtersArray, function (item, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "trendingFilter__link",
                  class: {
                    "trendingFilter__link--active":
                      index === _vm.activeTrendingFilterIndex,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeTrendingFilter(index)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeDropdown,
                  expression: "closeDropdown",
                },
              ],
              staticClass: "dropdown dropdown--customSize",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "formInput formInput--fullWidth formInput--selectCustom",
                  on: {
                    click: function ($event) {
                      _vm.isDropdownOpen = !_vm.isDropdownOpen
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.filtersArray[_vm.activeTrendingFilterIndex].name)
                  ),
                  _c("icon", { attrs: { name: "arrow-down" } }),
                ],
                1
              ),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDropdownOpen,
                      expression: "isDropdownOpen",
                    },
                  ],
                  staticClass: "dropdown__menu dropdown__menu--withInput",
                },
                _vm._l(_vm.filtersArray, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "dropdown__item dropdown__item--sm",
                      class: {
                        isActive: index === _vm.activeTrendingFilterIndex,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeTrendingFilter(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }