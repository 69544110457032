import { get } from '~/services/http-requests'

const _BS_PROMOTED_BEATS = 'promoted_beats'

/*
 **** GET ****
 */
async function fetchPromotedBeats(placement: IPromotion['placement']): Promise<IBeat[]> {
    const beats: IPromotedBeat[] = await get({ baseProperty: _BS_PROMOTED_BEATS, fullUrl: `${_BS_PROMOTED_BEATS}/?placement=${placement}` })
    return beats.map(it => ({ ...it.beat, isPromoted: true }))
}

export { fetchPromotedBeats }
