import { get } from '~/services/http-requests'
import { _BS_BEATS, BeatFilterType } from '~/api/beats'

const _BS_TRENDING_BEATS = 'trending_beats'
const _BS_RANDOM_BEATS = 'random_beats'

export enum TrendingPeriod {
    Today = 'Today',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year',
    AllTime = 'AllTime'
}

export interface TrendingFilter {
    name: string
    period: TrendingPeriod
    genreId?: number
}

/*
 **** GET ****
 */
async function fetchBeatWithDemoDownload(): Promise<IBeat> {
    const data = await get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?demo_download_allowed=true&limit=1` })
    return data.results ? data.results[0] : data[0]
}

async function fetchTrendingBeats(limit: number, period: string, genreId: IGenre['id']): Promise<IBeat[]> {
    let url = `${_BS_TRENDING_BEATS}.json?limit=${limit}&period=${period}`
    if (genreId) url += `&genre=${genreId}`
    const data = await get({ baseProperty: _BS_TRENDING_BEATS, fullUrl: url })
    const trendingBeats: ITrendingBeat[] = data.results
    return trendingBeats.map(it => ({
        ...it.beat,
        trending_state: it.state,
        trending_index: it.index
    }))
}

async function fetchRandomBeats(): Promise<IBeat[]> {
    const data = await get({ baseProperty: _BS_RANDOM_BEATS })
    return data.results ? data.results : data
}

export { fetchBeatWithDemoDownload, fetchTrendingBeats, fetchRandomBeats }
